import { $t } from 'o365-utils';
import { h, defineAsyncComponent } from 'vue'

const componentDefinitionsCache = new Map<string, any>();

export default function useAsyncComponent(pImport: string, pOptions?: {
    /** Show default loading spinner */
    showSpinner?: boolean,
    /** Custom loading component */
    loadingComponent?: any,
    /** Delay before the loading component is shown */
    delay?: number,
    /** Custom error component */
    errorComponent?: any,
    /** Default error component */
    errorType?: 'card' | 'span',
    /** Alt import route if the standard import path is not handling */
    altImport?: string,
    /** External import function used in lbraries. Needed to ensure scope remains in the library */
    importFn?: (pImport: string) => Promise<any>,
}) {
    // import('o365-vue-services').then(x => {
    //     x.alert(pImport, 'info')
    // });
    if (!componentDefinitionsCache.has(pImport)) {
        let ErrorComponent = null;;
        if (pOptions) {
            if (pOptions.errorComponent) {
                ErrorComponent = pOptions.errorComponent;
            } else if (pOptions.errorType === 'card') {
                ErrorComponent = ErrorCard;
            } else if (pOptions.errorType === 'span') {
                ErrorComponent = ErrorSpan;
            }
        }
        componentDefinitionsCache.set(pImport, defineAsyncComponent({
            // the loader function
            loader: async () => {
                if (pOptions?.importFn) {
                    let componentModule = await pOptions.importFn(pImport);
                    
                    if (componentModule?.default) {
                        componentModule = componentModule.default;
                    }
                    
                    return componentModule;
                }
                
                if (pOptions?.altImport) {
                    try {
                        return await import(pImport);
                    } catch {
                        return await import(pOptions.altImport);
                    }
                }

                return import(pImport);
            },
            errorComponent: ErrorComponent,
            // A component to use while the async component is loading
            loadingComponent: pOptions?.loadingComponent ? pOptions.loadingComponent : undefined,
            // Delay before showing the loading component. Default: 200ms.
            delay: pOptions?.delay ? pOptions.delay : 200,
        }));
    }
    return componentDefinitionsCache.get(pImport);
}

function ErrorSpan() {
    return h('span', {
        class: 'text-danger',
        title: $t('An error has occurred when loading this component')
    }, [
        h('i', { class: 'bi bi-exclamation-triangle-fill me-1' }),
        $t('Loading Error')
    ]);
}
function ErrorCard() {
    return h('div', { class: 'card text-danger mx-auto my-auto' },
        h('div', { class: 'card-body' }, [
            h('h5', { class: 'card-text' }, [
                h('i', { class: 'bi bi-exclamation-triangle-fill me-1' }),
                $t('Loading Error'),
            ]),
            h('p', { class: 'card-text' }, $t(('An error has occurred when loading this component'))),
        ]));
}